<template>
  <el-cascader
    ref="serviceCascader"
    :style="customStyle"
    placeholder="请选择服务项"
    v-model="checkData"
    :props="props"
    clearable
    :show-all-levels="false"
    @change="changeListener">
  </el-cascader>
</template>

<script>
  import {checkIsNull} from '../../utils'

  export default {
    name: 'service-item-cascader',
    model: {
      prop: 'itemCode',
      event: 'change'
    },
    props: {
      itemCode: {
        type: String,
        default: ''
      },
      // 自定义样式
      customStyle: {
        type: String,
        default: () => {
          return ''
        }
      }
    },
    data () {
      return {
        // 选择器配置字段
        props: {
          label: 'itemName',
          value: 'itemCode',
          children: 'children',
          leaf: 'isLeaf',
          lazy: true,
          lazyLoad: this.loadChildItem,
          checkStrictly: true
        },
        checkData: '',
        dataCache: {}
      }
    },
    watch: {
      checkData: function (newVal) {
        if (checkIsNull(newVal) || newVal.length === 0) {
          this.changeListener([])
        }
      }
    },
    created () {
      this.checkData = this.itemCode
    },
    methods: {
      loadChildItem (node, resolve) {
        let parentId = '00'
        if (node.root) {
          parentId = '00'
        } else {
          parentId = node.data.id
          if (node.data.isLeaf === 1) {
            return resolve([])
          }
        }
        this.$http({
          url: this.$http.adornUrl(this.$api.CF.SERVICE_ITEM.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'parentId': parentId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            data.list.forEach((i) => {
              this.dataCache[i.itemCode] = i
            })
            return resolve(data.list)
          } else {
            return resolve([])
          }
        }).catch(() => {
          return resolve([])
        })
      },
      changeListener (val) {
        let itemCode = null
        if (val.length > 0) {
          itemCode = val[val.length - 1]
        }
        let clickItem = itemCode ? this.dataCache[itemCode] : {}
        // v-model返回值
        this.$emit('change', itemCode)
        this.$emit('clickItem', clickItem)
        this.$refs.serviceCascader.toggleDropDownVisible()
      }
    }
  }
</script>

<style scoped>

</style>
